<template>
    <div>
        <div v-if="$vuetify.breakpoint.mdAndDown">
            <v-layout row wrap align-center justify-center fill height>
                <v-flex xs12>
                    <span class="subtitle-1">
                        <v-icon left>{{ icon }}</v-icon> 
                        {{ title }}
                    </span>   
                </v-flex>
                <v-flex xs12>
                    <v-btn dark color="error" @click="showDialog()" block outlined>
                        <v-icon small left>mdi-forward</v-icon>
                        Pošlji
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>
        <div v-else>
            <v-list>
                <v-list-item
                @click="showDialog()"
                >
                    <v-list-item-icon v-if="icon != '' && icon != null">
                        <v-icon color="">{{ icon }}</v-icon>
                    </v-list-item-icon>
            
                    <v-list-item-content >
                        <v-list-item-title class="body-2" v-text="title"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn dark color="error" small @click="showDialog()">
                            <v-icon small left>mdi-forward</v-icon>
                            Pošlji
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </div>

        <v-dialog v-model="dialog" scrollable max-width="650px">
            <v-card>
                <v-card-title id="dialog_card_title">
                    Ste prepričani, da želite uporabniku poslati obvestilo o stanju vloge številka #{{ application.id }}?
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <br>
                    <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                    Spoštovani <strong>{{ application.user.name }}</strong>,<br>
                    Vaša vloga za izdajo dovolilnice je bila zavrnjena.
                    <br><br>
                    Tip vloge: <strong>{{ application.permit_type.name }}</strong>
                    <br>
                    Številka vloge: <strong>{{ application.id }}</strong>

                    <br><br>
                    <v-textarea
                    outlined
                    name="input-7-4"
                    label="Opombe"
                    v-model="note"
                    counter
                    required
                    ></v-textarea>
                    
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn :disabled="disableSubmit" :loading="loading" color="success" text @click="sendEmail()"><v-icon left>mdi-arrow-right</v-icon>Pošlji</v-btn>
                    <v-btn :disabled="disableSubmit" color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ closeWindow }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'reject-application-notification',
    props: ['application'],
    components: {
        FormSubmitMessages
    },

    data: () => ({
        title: "Pošlji sporočilo uporabniku o zavrnjeni vlogi",
        icon: 'mdi-cancel',

        dialog: false,
        note: null,
        disableSubmit: false,
        loading: false,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        closeWindow: "Prekliči"

    }),

    computed: {
        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },
    },

    methods: {
        sendEmail() {
            this.clearMessages()
            this.loading = true
            this.disableSubmit = true

            this.$store.dispatch('REJECT_APPLICATION_EMAIL', {
                application_id: this.application.id,
                note: this.note,
                user_name: this.application.user.name,
                user_email: this.application.user.email,
                user_id: this.application.user.id,
                permit_type: this.application.permit_type.name

            })
            .then(response => {
                //window.console.log(response)

                this.backendMessages.push("Pošiljanje e-mail sporočila je uspelo.");
                this.closeWindow = 'Zapri'

            })
            .catch(error => {
                window.console.error("RejectApplicationNotification@sendEmail");
                window.console.log(error)
                window.console.log(error.response)

                this.backendErrorMessages.push('Pri pošiljanju elektronskega sporočila je prišlo do napake.');
            })
            .finally(() => {
                this.loading = false
                this.disableSubmit = false
            })


        },

        showDialog() {
            this.dialog = true
        },

        cancel() {
            this.clearForm();
            this.dialog = false
        },

        clearForm() {
            this.backendMessages = []
            this.backendErrorMessages = []
            this.note = null
        },

        clearMessages() {
            this.backendMessages = []
            this.backendErrorMessages = []
        }
    },

    created() {

    },

    mounted() {
        //window.console.log("Application ...")
        //window.console.log(this.application)
    },

    destroyed() {

    }
}

</script>

<style scoped>
    #dialog_card_title {
        font-size: 16px !important;
    }
</style>